import React, { useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import style from './navigation.module.scss';

const Navigation = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        {
          allCockpitPage(filter: { inNavigation: { value: { eq: true } } }) {
            edges {
              node {
                id
                path {
                  value
                }
                title {
                  value
                }
              }
            }
          }
        }
      `}
      render={({ allCockpitPage }) => {
        const navItems = allCockpitPage.edges.map(({ node }) => {
          return {
            path: node.path.value,
            title: node.title.value,
            id: `nav_${node.id}`,
          };
        });

        return (
          <nav
            className={`${style.navigation}${isOpen ? ` ${style.open}` : ''}`}
          >
            <button
              className={style.burger}
              onMouseEnter={() => setOpen(true)}
              onClick={_ => {
                setOpen(!isOpen);
              }}
            >
              <div />
              <div />
              <div />
            </button>
            <ul onMouseLeave={() => setOpen(false)}>
              <li>
                <Link
                  to="/"
                  className={style.navlink}
                  onClick={() => setOpen(false)}
                >
                  Home
                </Link>
              </li>
              {navItems.length > 0 &&
                navItems.map(item => (
                  <li key={item.id}>
                    <Link
                      to={item.path}
                      className={style.navlink}
                      onClick={() => setOpen(false)}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </nav>
        );
      }}
    />
  );
};

export { Navigation };
