import React, { useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars/lib/Scrollbars';
import style from './discord-widget.module.scss';

const DiscordLogo = ({ color = '#fff', width = 48, height = 48 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={width}
    height={height}
  >
    <path
      fill={color}
      d="M40 12s-4.586-3.59-10-4l-.488.977C34.406 10.176 36.652 11.89 39 14c-4.047-2.066-8.04-4-15-4-6.96 0-10.953 1.934-15 4 2.348-2.11 5.02-4.016 9.488-5.023L18 8c-5.68.535-10 4-10 4s-5.121 7.426-6 22c5.16 5.953 13 6 13 6l1.64-2.184C13.856 36.848 10.716 35.121 8 32c3.238 2.45 8.125 5 16 5s12.762-2.55 16-5c-2.715 3.121-5.855 4.848-8.64 5.816L33 40s7.84-.047 13-6c-.879-14.574-6-22-6-22zM17.5 30c-1.934 0-3.5-1.79-3.5-4s1.566-4 3.5-4 3.5 1.79 3.5 4-1.566 4-3.5 4zm13 0c-1.934 0-3.5-1.79-3.5-4s1.566-4 3.5-4 3.5 1.79 3.5 4-1.566 4-3.5 4z"
    />
  </svg>
);

const DiscordWidget = ({ serverId, invite }) => {
  const [discordData, setDiscordData] = useState({});
  const [panelOpen, setPanelOpen] = useState(false);
  let timeout;

  const inviteUri = 'https://discord.gg/';

  const inviteLink =
    Boolean(invite) && !invite.startsWith(inviteUri)
      ? inviteUri + invite
      : invite;

  /**
   * fetches Data from Discord widget api
   */
  const fetchData = async () => {
    const newData = await fetch(
      `https://discordapp.com/api/guilds/${serverId}/widget.json`,
      {
        cache: 'no-cache',
      }
    ).then(data => {
      // since there is a rate-limit in Discord API we
      // decide to refresh just once in 2 minutes
      timeout = setTimeout(fetchData, 120000);
      return data.json();
    });

    // find groovy bot
    const groovy = newData.members.find(
      ({ username }) => username.toLowerCase() === 'groovy'
    );

    newData.channels.sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });

    // add music tag to channel groovy bot plays on
    if (Boolean(groovy)) {
      newData.channels.forEach((channel, index) => {
        newData.channels[index].music = channel.id === groovy.channel_id;
      });
    }

    // remove all bots from members
    newData.members = newData.members.filter(
      ({ username }) => username && username.toLowerCase() !== 'groovy'
    );

    setDiscordData(newData);
  };

  const createMember = ({
    id,
    avatar_url,
    username,
    status,
    self_deaf,
    self_mute,
    game,
  }) => (
    <li key={`member-${id}`} className={style.flex}>
      <div className={`${style.imageWrapper} ${style[status]}`}>
        <img className={style.avatar} src={avatar_url} alt={username} />
      </div>
      <div className={style.userWrapper}>
        <div className={style.userName} title={username}>
          {username}
        </div>
        {game && game.name && (
          <div className={style.game} title={game.name}>
            {game.name}
          </div>
        )}
      </div>
      {self_mute && (
        <span className={style.muteWrapper}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 484.5 484.5"
            width="10"
            height="10"
            className={style.mutedButton}
          >
            <path d="M420.75 229.5H377.4c0 17.85-5.101 35.7-10.2 51l30.6 30.6c15.3-22.95 22.95-51 22.95-81.6zM318.75 234.6V76.5c0-43.35-33.15-76.5-76.5-76.5s-76.5 33.15-76.5 76.5v5.1l153 153zM45.9 25.5L12.75 58.65l153 153v17.85c0 43.35 33.15 76.5 76.5 76.5 5.1 0 10.2 0 17.85-2.55l43.351 43.35c-17.851 7.65-38.25 12.75-58.65 12.75-71.4 0-135.15-53.55-135.15-130.05h-45.9c0 86.7 68.85 158.1 153 170.85v84.15h51v-84.15c22.95-2.55 45.9-12.75 63.75-22.949l107.1 107.1 33.15-33.15L45.9 25.5z" />
          </svg>
        </span>
      )}
      {self_deaf && (
        <span className={style.muteWrapper}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 461.55 461.55"
            width="10"
            height="10"
            className={style.mutedButton}
          >
            <path d="M345.525 229.5c0-45.9-25.5-84.15-63.75-102v56.1l63.75 63.75V229.5zm63.75 0c0 22.95-5.1 45.9-12.75 66.3l38.25 38.25c17.85-30.6 25.5-68.85 25.5-107.1 0-109.65-76.5-201.45-178.5-224.4V56.1c73.95 25.5 127.5 91.8 127.5 173.4zM34.425 0L1.275 33.15 121.125 153H1.275v153h102l127.5 127.5V262.65l109.65 109.65c-17.851 12.75-35.7 22.95-58.65 30.601v53.55c35.7-7.65 66.3-22.95 94.35-45.9l51 51 33.15-33.149-229.5-229.5L34.425 0zm196.35 25.5l-53.55 53.55 53.55 53.55V25.5z" />
          </svg>
        </span>
      )}
    </li>
  );

  useEffect(() => {
    fetchData();
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nonVoiceMembers =
    Boolean(discordData.members) &&
    discordData.members.filter(({ channel_id }) => !Boolean(channel_id));

  return (
    <div className={style.discordWrapper}>
      <button
        className={style.toggleButton}
        onClick={() => {
          if (!panelOpen) {
            fetchData();
          }

          setPanelOpen(!panelOpen);
        }}
      >
        <DiscordLogo />
        <span className={style.online}>
          {Boolean(discordData.members) ? discordData.members.length : 0}
        </span>
      </button>
      <div
        className={style.discordWidget}
        style={{ display: panelOpen ? 'block' : 'none' }}
      >
            <h3>
              {Boolean(invite) ? (
                <a
                  href={inviteLink}
                  title="join"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DiscordLogo width={18} height={18} /> Discord
                </a>
              ) : (
                <>
                  <DiscordLogo width={18} height={18} /> Discord
                </>
              )}
            </h3>
            <ul className="members">
              {Boolean(discordData.channels) &&
                discordData.channels.map(({ id, name, music }) => {
                  const channelMember = discordData.members.filter(
                    ({ channel_id }) => channel_id === id
                  );
                  return (
                    <li key={`channel-${id}`}>
                      <h4>
                        {name}{' '}
                        {music && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 415.963 415.963"
                            width="20"
                            height="20"
                            className={style.playButton}
                          >
                            <path d="M328.712 264.539c12.928-21.632 21.504-48.992 23.168-76.064 1.056-17.376-2.816-35.616-11.2-52.768-13.152-26.944-35.744-42.08-57.568-56.704-16.288-10.912-31.68-21.216-42.56-35.936l-1.952-2.624c-6.432-8.64-13.696-18.432-14.848-26.656-1.152-8.32-8.704-14.24-16.96-13.76a15.957 15.957 0 0 0-14.88 15.936v285.12c-13.408-8.128-29.92-13.12-48-13.12-44.096 0-80 28.704-80 64s35.904 64 80 64 80-28.704 80-64V165.467c24.032 9.184 63.36 32.576 74.176 87.2-2.016 2.976-3.936 6.176-6.176 8.736-5.856 6.624-5.216 16.736 1.44 22.56 6.592 5.888 16.704 5.184 22.56-1.44 4.288-4.864 8.096-10.56 11.744-16.512.384-.448.737-.928 1.056-1.472z" />
                          </svg>
                        )}
                      </h4>
                      <ul>
                        {channelMember.length ? (
                          channelMember.map(createMember)
                        ) : (
                          <li>Niemand hier</li>
                        )}
                      </ul>
                      <hr style={{ borderColor: 'white' }} />
                    </li>
                  );
                })}
              <h4>Nicht im Voice</h4>
              <ul>
                {Boolean(nonVoiceMembers) && nonVoiceMembers.length ? (
                  nonVoiceMembers.map(createMember)
                ) : (
                  <li>Niemand hier</li>
                )}
              </ul>
            </ul>
      </div>
    </div>
  );
};

export { DiscordWidget, DiscordLogo };
