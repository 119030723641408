const React = require('react');
const DiscordWidget = require('./src/components/discord-widget').DiscordWidget;
const Link = require('gatsby').Link;
const Navigation = require('./src/components/navigation/navigation').Navigation;

exports.wrapPageElement = ({ element }) => {
  return (
    <>
      <div style={{ position: 'relative', width: '100%' }}>
        <Navigation />
        <DiscordWidget
          serverId={`${process.env.GATSBY_DISCORD_SERVER}`}
          invite={`${process.env.GATSBY_DISCORD_INVITE}`}
        />
        {element}
      </div>
      <footer>
        <div>© {new Date().getFullYear()} K9 Kommando</div>
        <ul>
          <li>
            <Link to="/impressum">Impressum und Datenschutz</Link>
          </li>
        </ul>
      </footer>
    </>
  );
};
